import React from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "../components/Layout";
import { ArrowRight, ArrowLeft } from "react-feather";

export default ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <Layout pageTitle="atölyeler" path="/workshops">
      <div className="blog-list">
        <h1>atölyeler</h1>

        {data.allFile.nodes.map(node => (
          <article className="blog-post-summary">
            <Link to={`/workshops/${node.childMdx.fields.slug}`}>
              <h2 className="post-title">{node.childMdx.frontmatter.title}</h2>
            </Link>
            <p>{node.childMdx.frontmatter.description}</p>
            <div className="read-more">
              <Link to={`/workshops/${node.childMdx.fields.slug}`}>başla</Link>
            </div>
          </article>
        ))}

        {!(isFirst && isLast) && (
          <nav className="pagination">
            <ul>
              {!isFirst && (
                <li className="previousPage">
                  <Link to={`/workshops/${prevPage}`} rel="prev">
                    <ArrowLeft width="18" height="18" viewBox="0 0 20 20" />
                  </Link>
                </li>
              )}
              {Array.from({ length: numPages }, (_, i) => (
                <li>
                  <Link
                    key={`pagination-number${i + 1}`}
                    to={`/workshops/${i === 0 ? "" : i + 1}`}
                  >
                    {i + 1}
                  </Link>
                </li>
              ))}
              {!isLast && (
                <li  className="nextPage">
                  <Link to={`/workshops/${nextPage}`} rel="next">
                    <ArrowRight width="18" height="18" viewBox="0 0 20 20" />
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query workshopsPageQuery($skip: Int!, $limit: Int!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "workshops" }
        extension: { eq: "mdx" }
      }
      sort: { fields: childMdx___fields___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        childMdx {
          id
          frontmatter {
            title
            description
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
